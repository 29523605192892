.device-modal-content {
  height: Calc(100vh - 200px);
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  /* font-family: 'MaryAnnRegular'; */
}

.device-left-container {
  display: flex;
  flex-direction: column;
}

.device-right-container {
  display: flex;
  flex-direction: column;
}

.access-point-item {
  font-size: 13px;
  font-weight: 600;
  padding: 10px;
  color: #000;
  border-bottom: 1px solid gray;
  background-color: #dcdcdc;
  flex: 1;
  display: flex;
  flex-direction: row;
}

.device-card {
  /* width: 45%; */
  height: auto;
  background-color: lightgray;
  padding: 15px;
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
  margin-right: 6px;
  /* flex: 1; */
  border-radius: 4px;
}

.device-flex {
  flex: 1;
}

.device-width {
  width: 49%;
}

.device-label {
  font-size: 11px;
}

.card-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.card-row b {
  font-size: 14px;
}

.card-row-space {
  margin-bottom: 15px;
}

.device-accordion {
  width: 100%;
}

.device-accordion-body {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.device-accordion-header {
  font-size: 16px;
  /* font-family: 'MaryAnnMedium'; */
}

.accordion-button:not(.collapsed) {
  background-color: #000;
  border: none;
  color: #fff;
  box-shadow: none;
}

.accordion-button {
  background-color: #f6f6f6;
}

.device-value-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.accordion-spinner-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.device-ratio {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding-right: 12px;
  margin-bottom: 12px;
}

.device-count {
  font-size: 22px;
}

.device-ratio .failed-device-count {
  background-color: #ff1f1f;
  color: #fff;
  width: 40%;
  padding: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
}

.count-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.icon-section {
  margin-left: 12px;
}

.device-ratio .total-device-count {
  background-color: #3f6eff;
  color: #fff;
  width: 40%;
  padding: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
}

.device-count-label {
  font-size: 13px;
  color: #d3d3d3;
}

.copy-icon{
  margin-left: 12px;
}
