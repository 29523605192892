.first-column {
  flex: 1;
  display: flex;
}

.dashboard-column {
  display: flex;
  background-color: rgb(160, 160, 160);
  width: 13%;
  height: Calc(100vh - 110px);
  border-radius: 25px;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 15px;
}

.column-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100px;
  color: #ffffff;
  justify-content: center;
  flex: 1;
  background-color: #404040;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  /* font-family: 'MaryAnnMedium'; */
}

.column-title {
  margin-left: 10px;
  font-size: 17px;
}

.row-item {
  display: flex;
  flex-direction: row;
  background-color: #404040;
  border-radius: 15px;
  margin-bottom: 18px;
  height: 56px;
  padding-right: 30px;
}

.device-status-card {
  width: 13%;
  margin-left: 15px;
  flex-direction: row;
  display: flex;
}

.cards-container{
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  display: flex;
  flex:1;
}

.access-card {
  width: 16%;
}

.status-card {
  width: 60px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 15px;
  animation-duration: 0.5s;
  animation-name: slidein;
}

.online {
  background-color: #00b050;

  /* animation-duration:1s;
  animation-name: rotate; */
}

.assigned {
  background-color: #f06c00;
  cursor: pointer;
}

.card-animation {
  width: 60px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
  animation-duration: 0.7s;
  animation-name: slidein;
}

.offline {
  cursor: pointer;
  background-color: #ff0000;
  animation: animate 1s ease-in-out infinite;
}
.count {
  animation: animate 1s ease-in-out infinite;
}
.full-card {
  width: 160px;
  /* height: 40px;
  font-size: 16px; */
}

.default {
  background-color: gray;
  color: #dcdcdc;
}

.row-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ffffff;
  justify-content: flex-start;
  flex: 1;
  padding-left: 10px;
}

.row-title {
  margin-left: 10px;
  font-size: 15px;
  /* font-family: 'MaryAnnRegular'; */
}

.logo {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  background-color: #F06C00;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  /* font-family: 'MaryAnnBold'; */
}

.logo-container {
  align-items: flex-start;
  justify-content: flex-start;
  height: 24%;
  width: 13%;
}

.page-loader {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  position: absolute;
}

@keyframes animate {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}

@keyframes slidein {
  from {
    margin-right: 50%;
    width: 0%;
  }

  /* 40% {
    margin-right: 25%;
    width: 25%;
  } */

  to {
    margin-right: 0%;
  }
}

@keyframes rotate {
  from {
    transform: rotateY(180deg);
    background-color: gray;
  }

  to {
    transform: rotateY(180deg);
  }
}
