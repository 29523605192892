.section-title {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f06c00;
  color: #fff;
  width: 15%;
  height: 90%;
  border-radius: 7px;
  text-align: center;
  font-size: 1.4vw;
  position: relative;
  /* font-family: MaryAnnBold; */
  flex-direction: column;
}

.section-item {
  width: 20%;
  display: flex;
  flex-direction: column;
  background-color: #404040;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 6px;
  height: 80%;
  padding: 10px;
  margin-left: 12px;
  border-radius: 7px;
  /* border-left: 4px solid #404040;
  border-right: 4px solid #404040; */
  /* font-family: MaryAnnMedium; */
}

.section-item-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.section-item-header h4 {
  color: #fff;
  font-size: 1.25vw;
}

.wireless .section-item{
  background-color: #95C0EA;
}

.wireless .section-item h4{
  color: #000;
}

@media (min-width: 320px) and (max-width: 481px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .section-title {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }

  .section-title h5 {
    margin-bottom: 0;
    font-size: 18px;
  }

  .title-icon {
    margin-right: 7px;
  }

  .section-item {
    width: 100%;
    margin-left: 0;
    margin-bottom: 7px;
  }

  .section-item-header h4 {
    font-size: 14px;
  }
}

/* @media (min-width:961px)  { // tablet, landscape iPad, lo-res laptops ands desktops  }
@media (min-width:1025px) { // big landscape tablets, laptops, and desktops  }
@media (min-width:1281px) { // hi-res laptops and desktops  } */
