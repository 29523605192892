.settingsContainer {
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.settingsContainer .tab-item {
  border-radius: 0;
}

.settingsContainer .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #f06c00;
  font-size: 16px;
}

.settingsContainer .nav-link {
  color: #2f4f4f;
  /* font-family: 'MaryAnnRegular'; */
}

.settingsContainer .nav-item {
  background-color: #F2F2F2;
}
