.page-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    flex-direction: column;
  }

  .page-loader-container{
    display: flex;
    flex-direction: row;
    background-color: #f6f6f6ba;
    align-items: center;
    height: 40px;
    padding: 12px;
    justify-content: space-between;
    /* font-family: 'MaryAnnRegular'; */
    border-radius: 4px;
    color: #f06c00;
    font-size: 20px;
  }