.dashboard-container {
  display: flex;
  padding: 20px;
  flex: 1;
  position: relative;
}

.column-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;
  margin-right: 30px;
}

.dashboard-row-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  flex: 1;
  margin: 20px;
  height: 100%;
}
