.device-status-card {
  width: 13%;
  margin-left: 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  display: flex;
}

.access-card {
  width: 16%;
}

.count-card {
  width: 60px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 16px;
  animation-duration: 0.5s;
  animation-name: slidein;
  /* font-family: "MaryAnnRegular"; */
  border-radius: 2px;
}

.success-count {
  background-color: #00b050;
}

.assigned-count {
  background-color: orange;
  cursor: pointer;
}

.card-animation {
  width: 60px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
  animation-duration: 0.7s;
  animation-name: slidein;
}

.failed-count {
  cursor: pointer;
  background-color: #ff0000;
  animation: animate 1s ease-in-out infinite;
}
.count,
.animate-count {
  animation: animate 1s ease-in-out infinite;
}

.full-card {
  width: 160px;
  /* height: 40px;
    font-size: 16px; */
}

.default-count {
  background-color: gray;
  color: #dcdcdc;
}

.time-interval-item {
  display: flex;
  font-size: 14px;
  background-color: #e3e3e3;
  color: #2f4f4f;
  border-radius: 5px;
  height: 32px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
  /* font-family: "MaryAnnRegular"; */
}

.time-interval-item-selected {
  background-color: #f06c00;
  color: white;
  /* font-family: "MaryAnnMedium"; */
}

.status-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.status-card {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  /* height: 75%; */
  font-size: 18px;
  border-radius: 4px;
}

.online-status {
  background-color: #00b050;
  border-right: 3px solid #00b050;
  color: #fff;
  cursor: pointer;
}

.offline-status {
  background-color: #d50000;
  border-right: 3px solid #d50000;
  color: #fff;
  cursor: pointer;
}

.default-status {
  background-color: #ddd;
  /* border-right: 3px solid #737373; */
  color: #737373;
}
