.network-dashboard-container {
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: Calc(100vh - 70px);
  padding: 5px;
  /* font-family: 'MaryAnnRegular'; */
}

.section-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  height: 22%;
  margin-left: 15px;
  margin-top: 5px;
}

.title-icons path {
  stroke: white;
}

.title-icons g {
  fill: white;
}

.network-dashboard-loader {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  flex: 1;
  height: 100%;
}

.parent-container {
  position: relative;
}

@media (min-width: 320px) and (max-width: 481px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .network-dashboard-container {
    overflow: auto;
    height: 100%;
    padding-bottom: 100px;
  }
  .section-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 5px;
  }

  .modal-open {
    padding-right: 0 !important;
  }

  .modal-content {
    width: 100%;
  }

  .modal-backdrop {
    height: 100%;
  }
}
