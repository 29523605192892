.charts-container {
  display: flex;
  flex-direction: column;
  background-color: #ededed;
  height: Calc(100vh - 60px);
  padding-bottom: 15px;
}

.charts-header {
  display: flex;
  height: 50px;
  background-color: white;
  width: 100%;
  padding: 5px 10px;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-top: 5px;
}

.charts-header-label {
  margin-left: 2px;
  font-size: 13px;
  color: #000;
}

.time-interval-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.chart-item-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  color: #000;
  padding: 10px;
  width: 50%;
  margin: 5px 0 0 5px;
  flex: 1;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-r-5 {
  margin-right: 5px;
}

.chart-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.chart-item-title {
  color: #4c4c4c;
  font-size: 16px;
  /* font-family: "MaryAnnBold"; */
}

.chart-item {
  position: relative;
  margin-top: 15px;
  width: 100%;
  height: 100%;
  /* font-family: "MaryAnnRegular"; */
}

.charts-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
}

.chart-view-loader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #d3d3d34a;
}

.time-interval-container input {
  font-size: 14px;
  border: 1px solid #aeaeae;
  border-radius: 5px;
  box-shadow: inset 0 2px 2px #e9e9e9;
  width: 200px;
  padding: 5px;
  /* font-family: "MaryAnnRegular"; */
}

.time-interval-container .react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #F06C00;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  font-size: 14px;
  content: "×";
}

.rbt-aux span {
  display: none;
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
}

.right-side-options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  width: 40%;
}

.select-by-store {
  font-size: 14px;
  margin-right: 12px;
  width: 100%;
  /* font-family: "MaryAnnRegular"; */
}

.select-by-store .dropdown-menu a {
  font-size: 14px;
  /* font-family: "MaryAnnRegular"; */
}

.select-by-store .form-control {
  font-size: 14px;
  /* font-family: "MaryAnnRegular"; */
}

.form-check-input:checked {
  background-color: #F06C00;
  border-color: #F06C00;
}
