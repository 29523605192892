.footer {
  width: 100%;
  height: 30px;
  background-color: #000;
  bottom: 0;
  position: fixed;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.footer-text {
  color: white;
  font-size: 13px;
  margin-right: 15px;
  /* font-family: "MaryAnnRegular"; */
}

.copy-right {
  margin-left: 12px;
  font-size: 13px;
}

.footer-right {
  display: flex;
  flex-direction: row;
}

.legend {
  display: flex;
  flex-direction: row;
  padding-right: 15px;
  align-items: center;
  /* font-family: "MaryAnnRegular"; */
}

.online-legend {
  width: 30px;
  height: 15px;
  background-color: #00b050;
  margin-right: 15px;
  border-radius: 2px;
}

.offline-legend {
  width: 30px;
  height: 15px;
  background-color: #ff0000;
  border-radius: 2px;
}

.assigned-legend {
  width: 30px;
  height: 15px;
  background-color: orange;
  margin-right: 15px;
  border-radius: 2px;
}

.legend span {
  color: #f6f6f6;
  font-size: 12px;
  margin-right: 6px;
}

@media (min-width: 320px) and (max-width: 481px) {
  .footer {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
    padding: 10px;
  }

  .footer-right {
    flex-direction: column;
    margin-top: 7px;
  }

  .copy-right {
    margin-left: 0;
    font-size: 13px;
    margin-top: 7px;
  }

  .network-legend {
    margin-bottom: 7px;
  }
}
