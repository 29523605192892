.offline-site-item {
  padding: 10px;
  margin-bottom: 5px;
  margin-left: 5px;
  /* border-radius: 4px;
  border: 2px solid #fbe6e6; */
  background-color: #fbe6e6;
  display: flex;
  flex-direction: column;
}

.offline-site-name {
  font-size: 16px;
  font-weight: 600;
  /* font-family: "MaryAnnMedium"; */
  color: #bd0000;
}

.online-body-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;
  max-height: Calc(100vh - 206px);
  width: 100%;
}

.online-site-item {
  padding: 10px;
  margin-bottom: 5px;
  margin-left: 5px;
  /* border-radius: 4px;
  border: 2px solid #eaf2eb; */
  background-color: #eaf2eb;
  display: flex;
  flex-direction: column;
}

.online-site-name {
  font-size: 16px;
  font-weight: 600;
  /* font-family: "MaryAnnMedium"; */
  color: #008000;
}

.network-source-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.network-source-label {
  color: #404040;
  font-size: 12px;
  /* font-family: "MaryAnnRegular"; */
}
.network-source {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  /* font-family: "MaryAnnMedium"; */
}

.sites-loader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.categorySection label {
  font-size: 13px;
  /* font-family: "MaryAnnRegular"; */
}

.categorySection .row {
  margin-bottom: 12px;
}

.modal-content {
  border-radius: 0;
}

.network-sites-modal {
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
}
