body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace";
}

/* @font-face {
  font-family: MaryAnnBold;
  src: url(./fonts/MarryAnnFonts/MaryAnnWebBold/maryannwebbold.woff)
    format("woff");
}

@font-face {
  font-family: MaryAnnBoldItalic;
  src: url(./fonts/MarryAnnFonts/MaryAnnWebBoldItalic/maryannwebbolditalic.woff)
    format("woff");
}

@font-face {
  font-family: MaryAnnExtraBold;
  src: url(./fonts/MarryAnnFonts/MaryAnnWebExtraBold/maryannwebextrabold.woff)
    format("woff");
}

@font-face {
  font-family: MaryAnnExtraBoldItalic;
  src: url(./fonts/MarryAnnFonts/MaryAnnWebExtraBoldItalic/maryannwebextrabolditalic.woff)
    format("woff");
}

@font-face {
  font-family: MaryAnnItalic;
  src: url(./fonts/MarryAnnFonts/MaryAnnWebItalic/maryannwebitalic.woff)
    format("woff");
}

@font-face {
  font-family: MaryAnnLight;
  src: url(./fonts/MarryAnnFonts/MaryAnnWebLight/maryannweblight.woff)
    format("woff");
}

@font-face {
  font-family: MaryAnnLightItalic;
  src: url(./fonts/MarryAnnFonts/MaryAnnWebLightItalic/maryannweblightitalic.woff)
    format("woff");
}

@font-face {
  font-family: MaryAnnMedium;
  src: url(./fonts/MarryAnnFonts/MaryAnnWebMedium/maryannwebmedium.woff)
    format("woff");
}

@font-face {
  font-family: MaryAnnMediumItalic;
  src: url(./fonts/MarryAnnFonts/MaryAnnWebMediumItalic/maryannwebmediumitalic.woff)
    format("woff");
}

@font-face {
  font-family: MaryAnnRegular;
  src: url(./fonts/MarryAnnFonts/MaryAnnWebRegular/maryannwebregular.woff)
    format("woff");
} */
