.settingSection {
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px;
  height: Calc(100vh - 60px);
  /* font-family: MaryAnnRegular; */
}

.dashboardSection {
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3px 0 0 0;
  margin-bottom: 10px;
}

.dashboardSection label {
  font-size: 14px;
}

.loader-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.settings-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.edit-icon {
  margin-left: 12px;
  cursor: pointer;
  margin-bottom: 7px;
}

.settingSection h4 {
  color: #7f0442;
  /* font-family: "MaryAnnBold"; */
}

.primary-button {
  background-color: #f06c00;
  box-shadow: none;
  border: none;
  border-radius: 2px;
}

.primary-button:active,
.primary-button:hover,
.primary-button:focus-visible {
  background-color: #f06c00;
}

.settings-sub-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
  /* border: 2px solid #dcdcdc; */
}

.sub-menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: auto;
  /* font-family: "MaryAnnMedium"; */
  color: #b6b6b6;
  /* border-right: 2px solid #dcdcdc; */
  cursor: pointer;
}

.sub-menu-item-selected {
  /* font-family: "MaryAnnMedium"; */
  color: #ffffff;
  background-color: #f06c00;
}

.border-right-none {
  border-right: none;
}

.content-container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding-top: 8px;
  margin-top: 8px;
  width: 100%;
  border-top: 2px solid #efefef;
}

.device-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  height: 50px;
  background-color: #efefef;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 5px;
}

.devices-container {
  display: flex;
  padding-bottom: 32px;
  overflow: auto;
  flex: 1;
  flex-direction: column;
  position: relative;
}

.search-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.alert-box-success {
  background-color: #daf9da;
  font-weight: 600;
  color: #4caf50;
}

.device-item-first-column {
  width: 30%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.device-item-column {
  width: 20%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.device-item-action {
  width: 10%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.category-title {
  color: #f06c00;
  font-size: 20px;
  /* font-family: "MaryAnnMedium"; */
}

.network-items-loader{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  flex-direction: column;
  color: #f06c00;
}

.empty-results-container{
  position: absolute;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #404040;
  /* font-family: 'MaryAnnMedium'; */
  font-size: 20px;
}