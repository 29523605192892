.tooltip-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.75);
}

.tooltip-label {
  font-size: 13px;
}

.tooltip-name {
  font-size: 14;
}

.tooltip-count {
  font-size: 14;
}
