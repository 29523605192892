.menu-item {
  display: flex;
  padding-left: 14px;
  padding-right: 14px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: #404040;
  cursor: pointer;
  height: 40px;
  /* border-right: 1px solid #dcdcdc; */
  /* font-family: "MaryAnnRegular"; */
}

.menu-item-label {
  margin-left: 7px;
  font-size: 14px;
}

.menu-item-disabled {
  cursor: auto;
  opacity: 0.5;
}

.menu-item-selected {
  color: #404040;
  border-bottom: 2px solid #f06c00;
  /* font-family: "MaryAnnBold"; */
}

.settings-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 12px;
}

.signed-in-user-name {
  font-size: 15px;
  color: #404040;
  /* font-family: "MaryAnnMedium"; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.settings-icon {
  margin-left: 12px;
  cursor: pointer;
}

.header-container {
  flex: 1;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.menu-item-container {
  flex-direction: row;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  margin-left: 12px;
}

.main-logo-container {
  /* font-family: "MaryAnnBold"; */
  /* margin-left: 20px; */
  white-space: nowrap;
  font-size: 1.3vw;
  padding: 0 15px;
  color: #f06c00;
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
}

.header {
    width: 100%;
    height: 40px;
    background-color: #fff;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    border-bottom: 1px solid #cdcdcd;
    position: fixed;
    z-index: 2;
    padding: 12px;
}

.network-online-legend {
  width: 30px;
  height: 15px;
  background-color: #00b050;
  border: 1px solid #00b050;
  border-right: 3px solid #00b050;
  margin-right: 15px;
  border-radius: 2px;
  margin-left: 5px;
}

.network-offline-legend {
  width: 30px;
  height: 15px;
  border: 1px solid #ff0000;
  border-right: 3px solid #ff0000;
  background-color: #ff0000;
  border-radius: 2px;
  margin-left: 5px;
}

.network-legend {
  display: flex;
  flex-direction: row;
  padding-right: 15px;
  align-items: center;
  /* font-family: "MaryAnnMedium"; */
  font-size: 13px;
  padding-left: 9 px;
  margin-right: 5px;
  color: #fff;
}

.header .btn-secondary {
  color: #404040;
  background-color: transparent;
  border: none;
  padding: 5px;
}

.header .btn-secondary:focus {
  color: #404040;
  background-color: transparent;
  border: none;
}

.header .dropdown-item{
  color: #f06c00;
}

.header .dropdown-item:hover,
.header .dropdown-item:active,
.header .dropdown-item:focus {
  background-color: #f06c00;
  color: #fff;
}

@media (min-width: 320px) and (max-width: 481px) {
  .main-logo-container {
    font-size: 18px;
    padding: 0 8px;
  }

  .menu-item{
    padding-left: 0;
  }
}
